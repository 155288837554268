@import "../../assets/styles/colors.scss";

.title {
    font-size: 1rem !important;

    &.capitalize-title {
        strong {
            text-transform: capitalize;
        }
    }

    & > a {
        &:hover {
            color: $black;
        }
    }
}
