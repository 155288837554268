@import "../../assets/styles/colors.scss";

.login-page {
    direction: rtl;
    display: flex;
    flex-direction: column;
    height: 100vh;


    & > main {
        flex: 1 1 auto;

        .logo {
            max-width: 450px;
            max-height: 200px;
            margin-bottom: 25px;
        }

        & > div.content-column {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        & > div:first-child {
            background-color: $gray--f2;
        }

        & > div:last-child {
            background-color: $green--00;
        }
    }

    & > footer {
        background-color: $gray--e6;

        .footer__list {
            list-style-type: none;
            margin: 5px 0;
            padding: 0;
            // text-align: right;

            &-item {
                display: inline-block;
                margin: 0 10px;

                position: relative;

                & > .contact-info {
                    display: flex;
                    flex-direction: column;
                    row-gap: 4px;
                    background-color: white;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 6px 12px;
                    position: absolute;
                    bottom: 100%;
                    right: -150%;

                    & > .contact-item {
                        display: flex;
                        flex-direction: row-reverse;
                        column-gap: 8px;

                        & > span {
                            cursor: pointer;
                        }

                        & > img {
                            width: 20px;
                            height: 20px;
                            object-fit: contain;
                        }

                        & > a {
                            direction: ltr;
                        }
                    }
                }

                & > .btn-contact-login {
                    padding: 0;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    color: $green--00;
                }

                .language--change {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    color: $green--00;
                    font-family: "Tahoma";
                    font-size: 0.75rem;
                    font-weight: bold;

                    &:focus {
                        border: none;
                        outline: none;
                    }
                }

                & > a {
                    color: $green--00;
                }
            }
        }

        .social-media__list {
            list-style-type: none;
            margin: 5px 0;
            padding: 0;
            // text-align: left;

            &-item {
                display: inline-block;
                margin: 0 3px;

                & > a {
                    & > .icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        border: 1px solid $green--00;

                        & > img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
}