@import "../../assets/styles/colors.scss";

.language--change {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: $green--00;
    font-family: "Tahoma";
    font-size: 0.75rem;
    font-weight: bold;

    &:focus {
        border: none;
        outline: none;
    }
}