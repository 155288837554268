@import "../../assets/styles/colors.scss";

.button {
    margin: 3px 4px;
    padding: 4px 8px;
    display: block;
    border: 2px solid;
    border-radius: 50px;
    width: 150px;
    text-transform: capitalize;

    &-default {}

    &-primary {
        & {
            background-color: $blue--27;
            border-color: $blue--27;
            color: $white--ef;
        }

        &-outline {
            background-color: transparent;
            border-color: $blue--27;
            color: $blue--27;
        }
    }

    &-danger {
        & {
            background-color: $red--ff6;
            border-color: $red--ff6;
            color: $white--ef;
        }

        &-outline {
            background-color: transparent;
            border-color: $red--ff6;
            color: $green--0b;
        }
    }
}
