@import "../../assets/styles/colors.scss";

.dashboard-page {
    position: relative;
    direction: rtl;
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: stretch;
    font-size: 0.75rem;
    margin: 0 auto;
    box-sizing: border-box;

    &.ltr {
        direction: ltr !important;

        & > main {
            & > aside {
                .aside-list {
                    border-right: 2px solid $gray--bf;
                    border-left: 0;
                }
            }
        }
    }

    & > header {
        font-size: 0.9rem;
        padding: 10px 0;
        text-transform: capitalize;

        & > nav {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            & > div.items-list {
                width: 100%;
                height: 100%;
                // padding-bottom: 25px;
                justify-content: flex-end;
                align-items: center;
                border-bottom: 2px solid $gray--bf;
            }
        }
        
        & > div > img.logo {
            max-width: 160px;
            max-height: 85px;
        }
    }

    & > main {
        height: calc(100% - 100px);

        & > aside {
            display: flex;
            flex-direction: column;

            .aside-list {
                border-left: 2px solid $gray--bf;
                border-right: 0;

                &__item {
                    text-transform: capitalize;
                    font-size: 0.9rem;
                    display: block;
                    margin: 25px 0;

                    &__link {
                        display: block;
                        color: $black;
                        font: inherit;
                        cursor: pointer;

                        & > img {
                            width: 24px;
                            height: 24px;
                            margin: 0 6px;
                        }

                        &:visited {
                            color: $black;
                        }
                    }
                }
            }
        }

        & > article {
            max-height: 100%;
            // overflow-y: scroll;
            // z-index: 10001;
            display: flex;
            flex-direction: column;

            // & > .message-box {

            // }
        }
    }

    & > footer.fixed-footer {
        position: absolute;
        bottom: 0;

        &.fa {
            left: 5%;
        }

        &.en {
            right: 5%;
        }

        & > img {
            max-width: 250px;
            max-height: 250px;
            opacity: 0.3;
        }
    }
}
