@import "../../assets/styles/colors.scss";

.cadastre-alert {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    padding: 8px 16px;
    margin: 5px;

    &-success {
        background-color: $green--1c;
        color: $white--ff;
        font-weight: bold;
    }
}