.chat-box {
    width: 100%;
    height: 240px;
    // overflow-y: scroll;
    border: 2px solid #000000;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 8px 24px;
    box-sizing: border-box;

    & > .chat-box__title {
        font-weight: bold;
    }

    & > .chat-box__main {
        border: 2px solid #000000;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > div {
            width: 100%;
            border-bottom: 2px solid #000000;
            // display: flex;

            & > input, & > textarea {
                border: none;
                outline: none;
                width: 100%;
                height: 100%;
                text-align: center;
            }

            & > button.ready-messages__btn {
                width: 100%;
                margin-bottom: 2px;
            }

            &.include-messages {
                position: relative;

                & > .ready-messages {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    background-color: white;
                    border: 1px solid #000;

                    & > div.ready-message {
                        cursor: pointer;
                        border: 1px solid #000;
                        border-right: 0;
                        border-left: 0;
                        padding: 3px 6px;
                        
                        &:hover {
                            background-color: #f2f2f2;
                        }

                        & > span {
                            font-size: 0.9rem;
                            font-weight: 600;
                        }
                    }
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    & > .chat-box__btn {
        & > button {
            width: 55px;
            background-color: transparent;
            border: 2px solid #000000;
            border-radius: 4px;
        }
    }
}
