$gray--e6: #E6E3DE;
$gray--f2: #F2F2F2;
$gray--bf: #bfbfbf;
$gray--d8: #d8d8d8;
$gray--a8: #a8a8a8;

$white--ef: #efefef;
$white--ff: #ffffff;

$black: #000000;

$green--00: #005152;
$green--0b: #0b4141;
$green--1c: #1c6061;
$green-76: #76ea95;
$green-8d: #8dc8c4;

$red--ff6: #ff6865;

$blue--27: #27559EFF;
$gray--ee: #eeeeee;
$gray--81: #818181;
$gray--80: #808080;
$gray--cd: #cdcdcd;
$gray--4B: #4B4B4B;
$gray--B4: #B4B4B4;
$gray--4d: #4d4d4d;
$gray--e4: #e4e4e4;
$gray--ef: #EFEFEF;
$gray--eb: #EBEBEB;
$gray--99: #999999;
$gray--90: #909090;
$gray--98: #989898;
$gray--64: #646464;
$gray--f0: #F0F0F0;
$gray--a4: #a4a4a4;
$gray--b3: #b3b3b3;
$gray--A3: #a3a3a3;
$gray--99: #999999;
$gray--66: #666666;
$gray--D0: #D0D0D0;
$gray--D9: #D9D9D9;
$gray--cc: #cccccc;
$gray--b1: #b1b1b1;

$cyan--21: #21D0C5;
$cyan--85: #85DCD6;
$cyan--ee: #eefefd;
$cyan--f6: #F6FFFE;
$cyan--f3: #F3FAF9;

$black--00: #000000;
$black--1a: #1A1A1A;
$black--32: #323232;
$black--33: #333333;
$black--18: #181818;

$white--f0: #F0F0F099;
$white--ff: #ffffff;
$white--f5: #f5f5f5;
$white--f7: #f7f7f7;
$white--f2: #F2F2F2;
$white--f3: #F3F3F3;
$white--f8: #F8F8F8;
$white--fb: #FBFBFB;

$orange--d0: #D08269;
$orange--fc: #FCF2EF;
$orange--80: #806C00;
$cream: #FEF7CD;

$red--dc: #DC4F23;
$red--ed: #ed8e8a;

$green--25: #25AC39;
$green--0b6: #0b6f63;

$blue--00: #00AFFD;
$blue--e9: #E9FEFD;

$yellow--fd: #FDD90E;
