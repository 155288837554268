@import "../../assets/styles/colors";

.error-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  direction: rtl;
  align-items: center;
  background-color: $red--ff6;
  color: $white--ff;

  & > * {
    margin: 30px 0;
  }
}