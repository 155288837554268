.about-us {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    &.bg-fa {
        background-image: url('../../assets/images/aboutus-fa.png');
    }

    &.bg-en {
        background-image: url('../../assets/images/aboutus-en.png');
    }
}
