@import "../../assets/styles/colors.scss";

.admin-panel {
    height: 100vh;

    & > .admin-panel__all {
        & > .action_buttons {
            display: flex;
            column-gap: 4px;
            padding: 16px 24px;
        }
    }

    & > .admin-panel__users-table {
        max-height: 350px;
        max-width: 75%;
        height: 100%;
        overflow-y: scroll;

        & > table {
            border-collapse: separate;
            border-spacing: 0;
            box-sizing: border-box;
            text-align: center;
            margin: 20px 40px;

            & > thead > tr {
                background-color: #dedede;
            }

            th,td {
                border: 0.09rem solid #4a4a4a !important;
                padding: 8px 12px;
            }
        }
    }
}
