@import "../../../assets/styles/colors.scss";

.message-box {
    width: 560px;
    height: 90px;
    border: 2px solid #dfdfdf;
    text-align: justify;
    margin: 0 auto 5px;
    box-sizing: border-box;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    color: #99c0bc;
    white-space: pre-line;
}

.map-message {
    width: 95%;
    margin-top: auto;
    margin-bottom: 0;

    .buttons-and-messenger {
        display: flex;
        justify-content: center;
        column-gap: 90px;
    }
}


.data-section {
    display: flex;
    column-gap: 12px;
    height: 45%;
    overflow-y: scroll;

    & > .tooltip-data_table {
        width: 100px;
        border: 2px solid #808080;
        border-radius: 8px;
        text-align: center;
        background-color: white;
        position: fixed;
        display: none;
        z-index: 3;
    }

    & > .data-table {
        direction: ltr;
        border-collapse: separate;
        border-spacing: 0;
        caption-side: top;
        box-sizing: border-box;
        table-layout: fixed;
        // background-color: #4a4a4a;
    
        & > caption {
            text-align: center;
            text-transform: capitalize;
        }

        th,td {
            border: 0.09rem solid #4a4a4a !important;
            padding: 2px;
        }

        & > thead {
            position: sticky;
            top: 0;
            z-index: 1;

            &.en-font {
                & > tr {
                    & > th, td {
                        font-family: "Calibri" !important;
                    }
                }
            }

            & > tr {
                & > th {
                    background-color: #808080;
                    color: $white--ff;
                    text-transform: capitalize;
                    text-align: center;
                    white-space: pre-line;
                    font-size: 9px;

                    &.horizontal-view {
                        -ms-writing-mode: tb-rl;
                        -webkit-writing-mode: vertical-rl;
                        writing-mode: vertical-rl;
                        rotate: 180deg;
    
                        &:first-child {
                            max-height: 30px;
                            word-break: break-all;
                            word-wrap: break-word;
                            white-space: pre-line;
                        }
                    }
                }
            
                & > td {
                    background-color: #bfbfbf;
                    color: $black--00;
                    text-transform: capitalize;
                    text-align: center;
                    user-select: none;
        
                    &.clickable-td {
                        cursor: pointer;
                    }
            
                    &.horizontal-view {
                        -ms-writing-mode: tb-rl;
                        -webkit-writing-mode: vertical-rl;
                        writing-mode: vertical-rl;
                        rotate: 180deg;
                        font-size: 8px;
                        font-weight: bold;
                    }
                }
            }
        }

        & > tbody {
            & > tr {
                height: fit-content;
                & > th {
                    background-color: #808080;
                    color: $white--ff;
                    text-transform: capitalize;
                    text-align: center;
                    border: 2px solid #4a4a4a;
                }
            
                & > td {
                    background-color: transparent;
                    color: $black--00;
                    text-transform: capitalize;
                    padding: 0;
                    user-select: none;
                    font-size: 10px;

                    & > input {
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        background-color: #ffffff;
                        border: none;
                        outline: none;
                        position: relative;

                        &.active-tooltip::after {
                            content: " ";
                            width: 60px;
                            height: 60px;
                            position: absolute;
                            background-color: red;
                            top: 0;
                            right: 0;
                        }

                        &:focus {
                            outline: none;
                        }

                        &:read-only {
                            cursor: pointer;
                            user-select: none;
                        }
                    }
        
                    // &.clickable-td {
                    //     cursor: pointer;
                    // }
                }
            }
        }
    
    }
}