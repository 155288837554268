@import "../fonts/yekan/yekan.scss";
@import "../fonts/b_nazanin/bNazanin.scss";
@import "../fonts/fontawesome/css/all.min.css";
@import "../styles/colors.scss";

* {
    font-family: "Yekan";
    // user-select: none;
}

.ltr {
    direction: ltr;
}

.rtl {
    direction: rtl;
}

.font-nazanin {
    font-family: "B Nazanin";
}

.font-smaller {
    font-size: small;
}

.text-end {
    text-align: end;
}

fieldset {
    width: fit-content;
    margin-bottom: 1em !important;
    border: 1px solid #666 !important;
    padding:1px !important;

    & > legend {
        padding: 1px 10px !important;
        float:none;
        width:auto;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style-type: none;
}

p, a, button, span {
    font-size: 0.75rem;
}

.w-100 {
    width: 100%;
}

.max-width-310px {
    max-width: 310px;
    width: 310px;
}

.margin-right-full {
    margin-left: 0 !important;
    margin-right: auto !important;
}

.margin-left-full {
    margin-left: auto !important;
    margin-right: 0 !important;
}

.margin-y10px {
    margin: 10px 0;
}

.margin-y12px {
    margin: 12px 0;
}

.font-weight--bold {
    font-weight: bold;
}

.pl-0 {
    padding-left: 0;
}

.margin-top-50px {
    margin-top: 50px;
}

.text-green {
    color: $green--0b;
}

.text-white {
    color: $white--ff;
}

.icon {
    width: 54px;
    height: 54px;
}

.text-red {
    color: $red--ff6;
}

.column-gap-12px {
    column-gap: 12px;
}

.btn {
    padding: 6px 8px;
    border-radius: 8px;
    border: 1px solid;
    color: $black;
    font-size: 0.75rem;
    width: 130px;
    height: 40px;
    font-weight: 400;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;

    &.btn-red {
        background-color: $red--ed;
        border-color: $red--ed;
    }

    &.btn-green {
        background-color: $green-76;
        border-color: $green-76;
    }

    &.btn-cyan {
        background-color: $green-8d;
        border-color: $green-8d;
    }
}

.one-line-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
