@import "../../../assets/styles/colors.scss";

.chats__container {
    border: 1px solid #ccc;
    max-width: 92%;
    height: 70dvh;
    // overflow: hidden;

    & > .chats__header {
        background-color: #ccc;
        padding: 8px 16px;
    }

    & > .chats__col {
        display: flex;
        flex-direction: column;
        column-gap: 8px;
        border: 1px solid #ccc;
        padding: 0;
        height: calc(100% - 43px);
        overflow-y: scroll;
        background-color: white;
        z-index: 1000000;

        & > .chat__item {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-bottom: 1px solid #ccc;
            padding: 8px 8px;
            cursor: pointer;
            row-gap: 12px;

            &:is(:hover, .active) {
                background-color: #f8f8f8;
                transition: all 0.5s;
            }

            &:last-child {
                border-bottom: none;
            }

            & > .chat__details {
                display: flex;
                // justify-content: space-between;
                align-items: center;
                column-gap: 4px;

                & > .like-avatar {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    text-align: center;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                    font-size: 1rem;
                }

                & > span {
                    font-size: 0.75rem;
                }

                & > div:last-child {
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            & > .chat__last-message {
                display: flex;
                justify-content: space-between;
                align-items: center;

                & > span {
                    &:first-child {
                        font-size: 1rem;
                        color: #94909086;
                    }

                    &:last-child {
                        padding: 4px 8px;
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }

    & > .chat__box {
        border: 1px solid #ccc;
        height: calc(100% - 43px);
        // overflow: hidden;
        display: flex;
        flex-direction: column;
        column-gap: 8px;
        background-color: #fff;
        padding: 0;
        justify-content: space-between;
        z-index: 1;

        & > .chat_messages {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            overflow-x: hidden;
            column-gap: 8px;
            row-gap: 8px;
            padding: 16px 4px;
            z-index: 1;

            & > .message {
                background-color: #f1f1f1;
                border-radius: 10px;
                padding: 4px 16px;
                max-width: 50%;
                width: fit-content;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                row-gap: 8px;

                & > div {
                    flex: 1 1 0;
                    & > span {
                        font-size: 1rem;
                        color: #333;
                        word-break: break-all;
                        white-space: wrap;
                    }
                }

                & > span {
                    font-size: 0.5rem;
                }
    
                &.user {
                    margin-right: auto;
                    margin-left: 0;
                    background-color: #EFF8FF;
                }
    
                &.me {
                    background-color: #EEFFDE;
                    margin-left: auto;
                    margin-right: 0;
                }
            }
    
            & > .not-exist-text {
                margin: auto;
                text-align: center;
                font-size: 1rem;
            }
        }

        & > .message__entrance {
            border: 1px solid #ccc;
            display: flex;
            position: relative;

            & > .message-input {
                border: none;
                outline: none;
                font-size: 0.75rem;
                height: 100%;
                width: 100%;
                padding: 8px 16px;
                direction: ltr;
            }

            & > .predefined-messages {
                position: absolute;
                // top: 100%;
                bottom: 41px;
                right: 0;
                width: 100%;
                // height: 60px;
                border: 1px solid #ccc;
                background-color: #fff;
                display: flex;
                flex-direction: column;
                z-index: 6;
                max-height: 200px;
                overflow-y: scroll;

                & > .predefined-message {
                    padding: 3px 6px;
                    border: 1px solid #ccc;
                    border-right: 0;
                    border-left: 0;
                    cursor: pointer;

                    &:hover {
                        background-color: #f2f2f2;
                    }

                    & > span {
                        font-size: 0.9rem;
                        font-weight: 500;
                    }
                }
            }

            & > .predefined-messages__btn {
                color: $white--ff;
                background-color: $red--ff6;
                font-size: 0.9rem;
                font-weight: 400;
                border: none;
                outline: none;
                padding: 8px 20px;
            }

            & > .send-message__btn {
                color: $white--ff;
                background-color: $green-76;
                font-size: 0.9rem;
                font-weight: 400;
                border: none;
                outline: none;
                padding: 8px 20px;
            }
        }
    }
}