.map {
    display: flex;
    row-gap: 5px;
    column-gap: 10px;
    height: 240px;
    margin: 10px auto;

    & > .tooltips__container {
        & > .map-tooltip__out {
            display: flex;
            // justify-content: center;
            align-items: center;
            column-gap: 8px;
            background-color: #ffffff;
            border: 1px solid #ccc;
            z-index: 100;
            height: fit-content;
            margin-bottom: 4px;
    
            & > .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url("./bg.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                min-height: 65px;
                min-width: fit-content;
                overflow: hidden;
    
                & > img {
                    max-width: 32px;
                    max-height: 32px;
                    object-fit: scale-down;
                    object-position: center;
                    margin-bottom: 8px;
                }
            }
    
            & > .text {
                & > span {
                    font-size: 0.8rem;
                    font-weight: 500;
                }
            }
        }
    }


    & > map {
        & > .map-tooltip {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 8px;
            position: fixed;
            background-color: #ffffff;
            border: 1px solid #ccc;
            z-index: 100;

            & > .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url("./bg.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                min-height: 65px;
                min-width: fit-content;
                overflow: hidden;

                & > img {
                    max-width: 32px;
                    max-height: 32px;
                    object-fit: scale-down;
                    object-position: center;
                    margin-bottom: 8px;
                }
            }

            & > .text {
                & > span {
                    font-size: 0.8rem;
                    font-weight: 500;
                }
            }
        }
    }
}
